<script setup lang="ts">
interface Props {
  number?: number;
}
const props = withDefaults(defineProps<Props>(), {
  number: 0,
});
</script>

<template>
  <div
    class="bg-almost-black flex h-7 w-7 items-center justify-center rounded-md text-center text-white"
  >
    <span class="align-middle">+{{ props.number }}</span>
  </div>
</template>

<style scoped></style>
