<script setup lang="ts">
interface Props {
  modelValue?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
});
const emit = defineEmits(["update:modelValue"]);
function toggle() {
  emit("update:modelValue", !props.modelValue);
}
</script>

<template>
  <div class="relative h-5 w-5 cursor-pointer" @click="toggle">
    <div v-if="modelValue" class="h-full w-full">
      <div class="bg-almost-black h-full w-full rounded-xs"></div>
      <svg
        class="absolute top-0 h-5 w-5"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5 12.4444L9.26035 17L19 7"
          stroke="white"
          stroke-width="1.7"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
    <div v-else class="h-full w-full">
      <div class="border-medium-gray h-full w-full rounded-xs border"></div>
    </div>
  </div>
</template>

<style scoped></style>
