import { KyInstance } from "ky";
import ApiAuth from "./auth";
import ApiUsers from "~/api/users";
import ApiAccounts from "~/api/accounts";
import ApiMultimedia from "~/api/multimedia";
import ApiDocuments from "~/api/documents";
import ApiModules from "~/api/modules";
import ApiWebauthn from "~/api/webauthn";
import ApiNotificationDevices from "~/api/notificationDevices";
import ApiBalanceSheets from "~/api/balanceSheets";

export interface Api extends KyInstance {
  auth: ApiAuth;
  users: ApiUsers;
  accounts: ApiAccounts;
  multimedia: ApiMultimedia;
  documents: ApiDocuments;
  modules: ApiModules;
  webauthn: ApiWebauthn;
  notificationDevices: ApiNotificationDevices;
  balanceSheets: ApiBalanceSheets;
}

export class ApiKy {
  ky: KyInstance;
  url: string;

  constructor(api: KyInstance, url: string) {
    this.ky = api;
    this.url = url;
  }
}
