<script setup lang="ts"></script>

<template>
  <CaIcon class="h-7 w-7" name="Close - large" />
</template>

<style scoped>
.Vue-Toastification__close-button,
.Vue-Toastification__close-button {
  opacity: 1;
}
</style>
