<script setup lang="ts">
interface Props {
  text?: string;
}
const props = withDefaults(defineProps<Props>(), {
  text: "",
});
</script>

<template>
  <div
    class="bg-almost-black inline-block w-fit rounded-sm px-2 py-0.5 text-center text-xs text-white"
    data-testclass="tag"
  >
    <span class="">{{ props.text }}</span>
  </div>
</template>

<style scoped></style>
