<script setup lang="ts">
import { PerformanceReturn } from "~/types/account/PerformanceReturn";
import PerformanceArrow from "~/components/PerformanceArrow.vue";

interface Props {
  performance: PerformanceReturn;
  colored?: boolean;
  noIcon?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  colored: false,
  noIcon: false,
});

const performanceIsPositive = computed(
  () => props.performance.returnRatio >= 0,
);

const coloredTextClass = computed(() => {
  if (!props.colored) {
    return "";
  }
  return performanceIsPositive.value ? "text-accent-green" : "text-accent-red";
});
</script>

<template>
  <span
    class="ml-[0.5em]"
    :class="coloredTextClass"
    data-testclass="performanceRelative-ratio"
  >
    <!-- keep }} on next line, otherwise we get a whitespace between + and number -->
    {{ performanceIsPositive ? "+" : ""
    }}{{ (performance.returnRatio * 100).toFixed(1) }}%
  </span>
  <span
    class="ml-[0.25em]"
    v-if="!noIcon"
    data-testclass="performanceRelative-arrow"
  >
    <PerformanceArrow
      :performance="performance.returnRatio"
      :colored="colored"
    />
  </span>
</template>

<style scoped></style>
