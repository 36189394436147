/* eslint-disable no-param-reassign */
import ky from "ky";
import { v4 as uuidv4 } from "uuid";
import { useStorage } from "@vueuse/core";
import { useAuthStore } from "~/store/useAuthStore";
import { useTokenStore } from "~/store/useTokensStore";
import { Api } from "~/api";
import ApiAuth from "~/api/auth";
import ApiUsers from "~/api/users";
import ApiAccounts from "~/api/accounts";
import ApiMultimedia from "~/api/multimedia";
import ApiDocuments from "~/api/documents";
import ApiModules from "~/api/modules";
import ApiWebauthn from "~/api/webauthn";
import ApiNotificationDevices from "~/api/notificationDevices";
import ApiBalanceSheets from "~/api/balanceSheets";
import posthog from "posthog-js";

const refreshCache: { inProgress: Promise<any> | undefined } = {
  inProgress: undefined,
};

const deviceId = useStorage("deviceId", uuidv4());

export const apiUrlP1 = import.meta.env.VITE_API_URL;
export const apiUrlTercero = apiUrlP1.replace("api.", "api2.");

export function useHttpClient() {
  function initKy() {
    let useTerceroApi = useTokenStore().getTR();

    return ky.create({
      prefixUrl: useTerceroApi ? apiUrlTercero : apiUrlP1,
      timeout: 30000,
      retry: {
        limit: 2,
        methods: [
          "delete",
          "get",
          "head",
          "options",
          "patch",
          "post",
          "put",
          "trace",
        ],
        statusCodes: [401, 408, 413, 429, 501, 502, 503, 504],
      },
      hooks: {
        beforeRetry: [
          async ({ request, options, error, retryCount }) => {
            /**
             * If refresh token request is pending
             * wait for it for resolve
             */
            if (refreshCache.inProgress !== undefined) {
              await refreshCache.inProgress;
            }
          },
        ],
        afterResponse: [
          async (request, _, response) => {
            if (response.status === 401) {
              /**
               * If there is no refresh token request pending
               * make a new one
               */
              if (refreshCache.inProgress === undefined) {
                refreshCache.inProgress = useAuthStore().refreshTokens();

                /**
                 * When refresh token request is resolved
                 * modify request headers and update user store
                 *
                 * Pay attention:
                 * Ky afterResponse hook shouldn't return a request,
                 * instead retry logic makes a request
                 * inside the correct execution context
                 * after new access token is set.
                 */
                refreshCache.inProgress
                  .then((tokens) => {
                    useTokenStore().setTokens(
                      tokens.token,
                      tokens.refreshToken,
                    );
                    request.headers.set(
                      "Authorization",
                      `Bearer ${tokens.token}`,
                    );
                    refreshCache.inProgress = undefined;
                  })
                  .catch(async () => {
                    useAuthStore().clearLocalStorage();
                    posthog.capture("Session expired");
                    window.location.replace(
                      `/auth/login?redirect=${window.location.pathname}${window.location.search}`,
                    );
                  });
              }
            } else if ([502, 503, 504].includes(response.status)) {
              if (!window.location.pathname.includes("maintenance")) {
                window.location.replace("/misc/maintenance");
              }
            } else if (response.status.toString().match(/5\d{2}/)) {
              if (!window.location.pathname.includes("500")) {
                window.location.replace("/misc/500");
              }
            } else {
              try {
                if (response.status === 204) {
                } else {
                  const body = (await response.json()) as any;
                  if (body.message === "USER_NOT_FOUND") {
                    // useToast().error("Couldn't find a user with this email");
                  }
                }
              } catch (e) {}
            }
          },
        ],
        // Add authorization header to requests
        beforeRequest: [
          (request) => {
            const accessToken = useTokenStore().getTokens.accessToken;
            console.log(request.url);
            //request.url = request.url.replace("https://api2.", "https://api.");

            if (accessToken && request.credentials !== "omit") {
              request.headers.set("Authorization", `Bearer ${accessToken}`);
            }
            if (
              request.url.endsWith("/auth") ||
              request.url.includes("auth/") ||
              request.url.includes("webauthn/")
            ) {
              request.headers.set("X-Client-Name", "web");
              request.headers.set("X-Device-Id", deviceId.value);
            }
          },
        ],
      },
    });
  }

  function initApis() {
    return {
      auth: new ApiAuth(kyInstance, "v1"),
      users: new ApiUsers(kyInstance, "v1"),
      accounts: new ApiAccounts(kyInstance, "v3"),
      multimedia: new ApiMultimedia(kyInstance, ""),
      documents: new ApiDocuments(kyInstance, "v1"),
      modules: new ApiModules(kyInstance, "v1"),
      webauthn: new ApiWebauthn(kyInstance, "v1"),
      notificationDevices: new ApiNotificationDevices(kyInstance, "v1"),
      balanceSheets: new ApiBalanceSheets(kyInstance, "v1"),
    };
  }

  let kyInstance = initKy();
  let api: Api = Object.assign(initApis());

  function switchApi() {
    kyInstance = initKy();
    api = Object.assign(initApis());
  }

  return { ...api, switchApi };
}
