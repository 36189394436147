<script setup lang="ts"></script>

<template>
  <CaModalWindow name="AddToHomescreenModal" modal-class="bottom-0">
    <div class="mb-4 text-lg">{{ $t("misc_add_to_homescreen_title") }}</div>
    <p class="mb-8 font-light">
      {{ $t("misc_add_to_homescreen_text") }}
    </p>
    <CaButton
      block
      data-testclass="accordionElement-header"
      @click="useGeneralStore().closeModal('AddToHomescreenModal')"
      >{{ $t("generic_button_close") }}</CaButton
    >
  </CaModalWindow>
</template>

<style scoped></style>
