<script setup lang="ts">
function initializeNotificationsIfGranted() {
  if (Notification.permission === "granted") {
    notificationsStore.initialize().then();
  }
}
async function obtainPermission() {
  useGeneralStore().closeModal("AllowNotificationsModal");
  await useNotificationsStore().obtainPermission();
  initializeNotificationsIfGranted();
}
const generalStore = useGeneralStore();
const notificationsStore = useNotificationsStore();
onMounted(() => {
  if ("Notification" in window) {
    if (Notification.permission === "default") {
      setTimeout(
        () => generalStore.openModal("AllowNotificationsModal"),
        60000,
      );
    } else {
      initializeNotificationsIfGranted();
    }
  }
});
</script>

<template>
  <CaModalWindow
    name="AllowNotificationsModal"
    modal-class="bottom-0 lg:left-auto py-6 lg:m-4 lg:rounded-md lg:shadow-2xl lg:max-w-lg"
  >
    <div class="mb-4 text-lg">Allow Notifications?</div>
    <p class="mb-8 font-light">
      Enable notifications to get notified about the latest content posted to
      CADRO.
    </p>
    <CaButton
      block
      class="mb-2"
      data-testid="allowNotificationsModal-button-accept"
      @click="obtainPermission"
      >Enable notifications</CaButton
    >
    <CaButton
      block
      color="secondary"
      data-testid="allowNotificationsModal-button-decline"
      @click="useGeneralStore().closeModal('AllowNotificationsModal')"
      >Not right now</CaButton
    >
  </CaModalWindow>
</template>

<style scoped></style>
