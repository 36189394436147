<script setup lang="ts">
interface Props {
  itemCount: number;
  initialOffset?: number;
}
const props = withDefaults(defineProps<Props>(), {
  itemCount: 0,
  initialOffset: 0,
});

const emit = defineEmits(["storeOffset"]);

const itemsCountClass = computed(() => {
  if (props.itemCount < 3) {
    return "fill-width";
  }
  return "";
});

const scr = ref<HTMLElement | null>(null);
const visibleItems = ref([] as number[]);

function itemEntered(item: number) {
  visibleItems.value.push(item);
}
function itemLeft(item: number) {
  let idx = visibleItems.value.indexOf(item);
  if (idx >= 0) {
    visibleItems.value.splice(idx, 1);
  }
}
onMounted(() => {
  if (scr.value && props.initialOffset) {
    scr.value.scrollLeft = props.initialOffset;
  }
});
onBeforeUnmount(() => {
  const firstVisibleIdx = Math.min(...visibleItems.value);
  const firstVisibleEl = scr.value?.children[
    Math.max(0, firstVisibleIdx - 1)
  ] as HTMLElement;
  if (firstVisibleEl) {
    emit("storeOffset", firstVisibleEl.offsetLeft);
  }
});
</script>

<template>
  <div
    class="carousel flex scroll-p-4 gap-x-4 gap-y-6 overflow-x-scroll px-4 sm:scroll-p-6 sm:gap-x-6 sm:px-6 lg:flex-wrap lg:overflow-x-visible lg:px-0"
    :class="itemsCountClass"
    ref="scr"
  >
    <CarouselItem
      v-for="itemNumber in itemCount"
      @item-entered="itemEntered(itemNumber)"
      @item-left="itemLeft(itemNumber)"
    >
      <slot :name="`carouselItem-${itemNumber}`" />
    </CarouselItem>
  </div>

  <div class="mx-4 my-6 sm:mx-6">
    <CarouselScrollbar
      v-if="itemCount > 1"
      class="lg:hidden"
      :item-count="props.itemCount"
      :visible-items="visibleItems"
    />
  </div>
</template>

<style scoped>
.carousel {
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
  & > * {
    shrink: 0;
    grow: 1;
    flex-basis: 0;
    min-width: min-content;

    &:nth-child(n + 3) {
      max-width: 19rem;
    }
  }
  &.fill-width :deep(> * > *) {
    max-width: none;
  }
}
</style>
