<script setup lang="ts">
import { userLocale } from "~/composables/useTranslation";

interface Props {
  value?: string;
  showTime?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  value: "",
  showTime: false,
});

const options: Intl.DateTimeFormatOptions = {
  // weekday: "short",
  year: "numeric",
  month: "short",
  day: "numeric",
};

const displayDateTime = computed(() => {
  if (!props.value) {
    return "";
  }
  const date = new Date(props.value);
  if (props.showTime) {
    return date.toLocaleTimeString(userLocale, options);
  } else {
    return date.toLocaleDateString(userLocale, options);
  }
});
</script>

<template>
  <span>{{ displayDateTime }}</span>
</template>

<style scoped></style>
