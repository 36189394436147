<script setup lang="ts">
import CountIcon from "~/components/CountIcon.vue";
import { Investment } from "~/types/account/Investment";

interface Props {
  investments?: Investment[];
  maxCount?: number;
}

const props = withDefaults(defineProps<Props>(), {
  investments: () => [],
  maxCount: -1,
});

const shownInvestments = computed(() => {
  if (props.maxCount < 0 || props.investments.length <= props.maxCount) {
    return props.investments;
  } else {
    return props.investments.slice(0, props.maxCount - 1);
  }
});
</script>

<template>
  <div v-if="investments.length" class="flex items-center gap-3">
    <object
      v-for="investment of shownInvestments"
      :data="investment.module.visualGroup?.emblemUrl"
      class="h-6 w-6"
      data-testclass="portfolioAssetClass-emblem"
    >
      <img src="/cash.svg" :alt="investment.module.displayName" />
    </object>
    <CountIcon
      v-if="maxCount >= 0 && shownInvestments.length < investments.length"
      :number="investments.length - shownInvestments.length"
      data-testclass="portfolioAssetClass-overflowCount"
    ></CountIcon>
  </div>
</template>

<style scoped></style>
