<script setup lang="ts">
import { useGeneralStore } from "~/store/useGeneralStore";
import { Performance } from "~/types/account/Performance";
import { PerformanceReturn } from "~/types/account/PerformanceReturn";
import PerformanceRelative from "~/components/PerformanceRelative.vue";
import { userLocale } from "~/composables/useTranslation";

interface Props {
  value?: number;
  currency?: string;
  performance?: Performance | null;
  colored?: boolean;
  showRelative?: boolean;
  noIcon?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  value: 0,
  currency: "GBP",
  colored: false,
  showRelative: true,
  noIcon: false,
});

const blurClass = computed(() =>
  useGeneralStore().blurCurrency ? "blur-sm" : "",
);

const showPerformance = computed(
  () => props.performance && useGeneralStore().showPerformance,
);

const currentPeriodPerformance = computed((): PerformanceReturn => {
  if (props.performance) {
    return props.performance[useGeneralStore().performancePeriod];
  }
  return {
    returnAmount: 0,
    returnRatio: 0,
  };
});

function formatCurrency(num: number) {
  return num.toLocaleString(userLocale, {
    style: "currency",
    currency: props.currency,
    maximumFractionDigits: 0,
    signDisplay: showPerformance.value ? "always" : "auto",
  });
}
</script>

<template>
  <span>
    <span v-if="showPerformance && performance" class="flex items-center">
      <span
        v-if="!showRelative"
        class="blurrable whitespace-nowrap"
        :class="blurClass"
      >
        {{ formatCurrency(currentPeriodPerformance.returnAmount) }}
      </span>
      <PerformanceRelative
        v-if="showRelative"
        :performance="currentPeriodPerformance"
        :colored="colored"
        :no-icon="noIcon"
      />
    </span>
    <span v-else class="blurrable" :class="blurClass">
      {{ formatCurrency(value) }}
    </span>
  </span>
</template>

<style scoped>
.blurrable {
  transition: 250ms filter ease-in-out;
  will-change: filter;
}
</style>
